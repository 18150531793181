import React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"
import "./blog.css"

export default ({ article }) => (
  <>
    <div className="article-container">
      <Row>
        <Col sm={4}>
        <div className="article-thumbnail">
        <Link to={`/news/${article.slug}`}>
          <img
            src={article.image ? article.image.fluid.src : null}
            style={{ maxWidth: "100%" }}
            alt={article.image?.title}
          />
        </Link>
      </div>
        </Col>
        <Col sm={8}>
        <div className="article-preview-text">
        <div className="article-card-title" style={{ marginBottom: "0px" }}>
          <Link className="article-title" to={`/news/${article.slug}`}>
            {article.title}
          </Link>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <small>{article.updatedAt}</small>
        </div>
        <p>{article.postExcerpt}</p>
      </div>
      
    {/* <div
      style={{ borderBottom: "1px solid lightgrey", marginBottom: "20px" }}
    /> */}
        </Col>
      </Row>
      </div>      
  </>
)
